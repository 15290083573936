import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare function handleAuthenticationUser(): any;
@Injectable()
export class AuthService {

  

    loggedIn = false;
    returnCred : {
      token :any ,
      userRole : any,
      userName : any,
      adminName:any,
      privileges : any
    }

    constructor( 
      protected router:Router
      ){}

    isAuthenticated() {

      if(localStorage.getItem('loginCredential') && handleAuthenticationUser()){
      
        this.loggedIn = true;
      }else{
     
        this.loggedIn = false;
      }
      //console.log("loginCheck",this.getLoginDAta());
      return this.loggedIn;
    }
  
    login(loginData : any) {
    console.log('authSer',loginData);
    if(!loginData.userName || loginData.userName == null || loginData.userName == ""){
      this.loggedIn = true;
    }else{
    localStorage.setItem("loginCredential",JSON.stringify(loginData)); 
    sessionStorage.setItem('CORE_SESSION', loginData.token); 
    // localStorage.setItem("loginCredential",JSON.stringify(loginData));  
    this.loggedIn = true;
    }
    }
  
    logout() {
      localStorage.removeItem("loginCredential");
      sessionStorage.removeItem('CORE_SESSION'); 
      this.loggedIn = false;
      this.returnCred = {
        token :null,
        userRole :null,
        userName : null,
        adminName:null,
        privileges :null
      }
      this.router.navigate(['/authentication/login']);
    }


    

    getLoginDAta(){

    if(localStorage.getItem('loginCredential') && sessionStorage.getItem('CORE_SESSION')){
      let lgc = JSON.parse(localStorage.getItem('loginCredential'));
      if(!lgc.userName || lgc.userName == null || lgc.userName == ""){
        this.logout()
        // return false
      }


     this.returnCred = {
      token : (lgc.token)?lgc.token:null,
      userRole : (lgc.userRole)?lgc.userRole : null,
      userName : (lgc.userName)?lgc.userName:null,
      adminName : (lgc.adminName)?lgc.adminName:null,
      privileges  : (lgc.privileges)?lgc.privileges:null
      }
    
      return this.returnCred;
    }else{
    this.logout();

    }      

    }


  }
  
