import { Component, OnInit, Output ,EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-footer-action',
  templateUrl: './footer-action.component.html',
  styleUrls: ['./footer-action.component.scss']
})
export class FooterActionComponent implements OnInit {
  @Input()footerConfig : any

  @Output()
  reverse = new EventEmitter<string>();
  // EventEmitter is for child parent communication
  constructor(private navigation: NavigationService,private appservice:AppService,private router:Router) { }

  ngOnInit() {

    console.log("footerConfig",this.footerConfig);
    

  }
back(){
  console.log("back");
  this.reverse.emit(null);
//   if(this.appservice.previousUrlId==1){
// this.router.navigateByUrl("/business-info")
//   }
//  else if(this.appservice.previousUrlId==2){
//     this.router.navigateByUrl("/upload-document")
//       }
// if(this.appservice.previousUrl==="/business-in"
// }

}

configBack(){
  this.router.navigateByUrl(`${this.footerConfig.backUrl}`)
}
}
