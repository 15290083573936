import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { paths } from "./const";
import { JsonPipe } from '@angular/common';
// declare function handleAuthenticationUser(): any;
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    
    if (paths.unauthUrl.includes(req.url)) {
      return next.handle(req);
    }
// var authenticate = handleAuthenticationUser()
//       if(authenticate){
// console.log("authenticated");

//       }else{

//         //   console.log("logout");
          
//       }

    // console.log("HeaderInterceptor");
    var AuthorizationToken = ""
    var loginCredential  = sessionStorage.getItem('CORE_SESSION')
    if(loginCredential){
        AuthorizationToken =  loginCredential//JSON.parse(loginCredential).token
    }
    // let token_properties = `{"userName": "IPPBdemo555","bankCode": "ippb","adminName": "ippbw457","role": ["ROLE_RETAILER"],"privileges":["AGENT_ONBOARDING","AGENT_CRUD","TRANSACTION_REPORT","PLAN_MANAGEMENT_CRUD"]}`


    const modified = req.clone({ setHeaders: { "Authorization": AuthorizationToken } });

    return next.handle(modified);
  }
}
