// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//var JavaEndPoint = "https://newapp.iserveu.online/INTERNAL_BQ_STAGE/"
var JavaEndPoint = "https://uatapps.iserveu.online/STAGE_USER_CREATE_MILAN/"
var JavaEndPointOffline = "https://newapp.iserveu.online/INTERNAL_BQ_STAGE/"
const cloudrunEnpoint = "https://apiprod.iserveu.tech/commission-set-ippb/"//"https://commission-set-ippb-staging-vn3k2k7q7q-uc.a.run.app/api/" //
// const cloudrunEnpoint = " https://apidev.iserveu.online/stagingauth/commission_ippb/api/" //staging
const localhostEnpoint = "http://localhost:8080/api/"
const loginUrl = "https://oauth2-auth-server-oauthjwt-uat-twdwtabx5q-el.a.run.app/oauth/token"//"https://itpl.iserveu.tech/getlogintoken"
const activeEndpoint = cloudrunEnpoint
export { cloudrunEnpoint, localhostEnpoint, activeEndpoint, loginUrl }


export const environment = {
  production: false,
  JavaEndPoint: JavaEndPoint,
  JavaEndPointOffline: JavaEndPointOffline

};

export const DMTId = 25


export const firebaseConfig = {
  apiKey: "AIzaSyATxpFql9-yuh2FKtTYJSDcGp4b1unNdXE",
  authDomain: "iserveumainapp.firebaseapp.com",
  databaseURL: "https://iserveumainapp.firebaseio.com",
  projectId: "iserveumainapp",
  storageBucket: "iserveu_storage",
  messagingSenderId: "421535327263",
  appId: "1:421535327263:web:096fd2fda95ddfaa5ae12a",
};
export const percentageMaxValue = 15