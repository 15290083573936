import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
  user: MainMenuItems[];
}

var AdminMenu = [
  {
    label: 'Navigation',
    main: [
      // {
      //   state: 'business-info',
      //   short_label: 'D',
      //   name: 'Business Information',
      //   type: 'link',
      //   icon: 'ti-home'
      // },
      // {
      //   state: 'upload-document',
      //   short_label: 'B',
      //   name: 'Upload Documents',
      //   type: 'link',
      //   icon: 'ti-layout-grid2-alt',
      // },
      {
        state: 'select-service',
        short_label: 'D',
        name: 'Select Service',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'add-service',
            type: 'link',
            name: 'Add Service',
            target: false
          },
          {
            state: 'serviceList',
            type: 'link',
            name: 'Service List',
            target: false
          },
        ]
      },
      {
        state: 'service-provider',
        short_label: 'D',
        name: 'Service Provider',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'admin-service-providers',
            type: 'link',
            name: 'Service provider List',
            target: false
          }, {
            state: 'create-admin-service-provider',
            type: 'link',
            name: 'Create Service Provider',
            target: false
          }
        ]
      },
      {
        state: 'plan-list',
        short_label: 'D',
        name: 'Plan Management',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'map-user-plan',
        short_label: 'D',
        name: 'Map User Plan',
        type: 'link',
        icon: 'ti-home'
      },
      // {
      //   state: 'benificial-owner',
      //   short_label: 'B',
      //   name: 'Benificial Owner',
      //   type: 'sub',
      //   icon: 'ti-layout-grid2-alt',
      //   children: [
      //     {
      //       state: 'benificial-owner',
      //       type: 'link',
      //       name: 'BenificialList',
      //       target: false
      //     }, {
      //       state: 'createbenificialOwner',
      //       type: 'link',
      //       name: 'Create Benificial-Owner',
      //       target: false
      //     }
      //   ]
      // },

      // {
      //   state: 'technical-details',
      //   short_label: 'D',
      //   name: 'Technical Details',
      //   type: 'sub',
      //   icon: 'ti-home',
      //   children: [
      //      {
      //       state: 'domain-details',
      //       type: 'link',
      //       name: 'Domain Details',
      //       target: false
      //     },
      //     {
      //       state: 'technical-details',
      //       type: 'link',
      //       name: 'Playstore Credential',
      //       target: false
      //     },
      //     {
      //       state: 'app-details',
      //       type: 'link',
      //       name: 'App Details',
      //       target: false
      //     },
      //   ]
      // },
      // {
      //   state: 'billing',
      //   short_label: 'D',
      //   name: 'Billing',
      //   type: 'link',
      //   icon: 'ti-home'
      // },
      // {
      //   state: 'timeline',
      //   short_label: 'D',
      //   name: 'Timeline',
      //   type: 'link',
      //   icon: 'ti-home'
      // }
    ],
    subUser : [
      {
        state: 'plan-list',
        short_label: 'D',
        name: 'Plan Management',
        type: 'link',
        icon: 'ti-home'
      }
    ],
    user : [
      {
        state: 'user-plan',
        short_label: 'D',
        name: 'UserPlan',
        type: 'link',
        icon: 'ti-home'
      },
    ],
  },

];



const MENUITEMS = AdminMenu





@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
