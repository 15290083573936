import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './authGuard.service';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      }, {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
      },
       {
        path: 'business-info',
        loadChildren: () => import('./pages/business-info/business-info.module').then(m => m.BusinessInfoModule)
      },
      {
        path: 'upload-document',
        loadChildren: () => import('./pages/upload-document/upload-document.module').then(m => m.UploadDocumentModule)
      },
      {
        path: 'benificial-owner',
        loadChildren: () => import('./pages/benificial-owner/benificial-owner.module').then(m => m.BenificialOwnerModule)
      },
      {
        path: 'select-service',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/select-service/select-service.module').then(m => m.SelectServiceModule)
      },
      {
        path: 'billing',
        loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingModule)
      },
      {
        path: 'technical-details',
        loadChildren: () => import('./pages/technical-details/technical-details.module').then(m => m.TechnicalDetailsModule)
      },
      {
        path: 'timeline',
        loadChildren: () => import('./pages/timeline/timeline.module').then(m => m.TimelineModule)
      },
       {
        path: 'notifications',
        loadChildren: () => import('./pages/ui-elements/advance/notifications/notifications.module').then(m => m.NotificationsModule)
      },

      {
        path: 'service-provider',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/service-provider/service-provider.module').then(m => m.ServiceProviderModule)
      },
      {
        path: 'service-provider-list',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/service-provider-list/service-provider-list.module').then(m => m.ServiceProviderListModule)
      },
      // {
      //   path: 'admin-service-providers',
      //   loadChildren: () => import('./pages/admin-service-provider-list/admin-service-provider-list.module').then(m => m.AdminServiceProviderListModule)
      // },
      {
        path: 'plan-list',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/plan-creation/plan-creation.module').then(m => m.PlanCreationModule)
      },
      {
        path: 'plan-feature',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/plan-feature/plan-feature.module').then(m => m.PlanFeatureModule)
      },
      {
        path: 'map-user-plan',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/map-user-plan/map-user-plan.module').then(m => m.MapUserPlanModule)
      },
      {
        path: 'plan-management/:planId/:planName',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/plan-details-create/plan-details-create.module').then(m =>m.PlanDetailsCreateModule)
      },
      {
        path: 'pagination',
        loadChildren: () => import('./pages/pagination/pagination.module').then(m => m.PaginationModule)
      },
      {
        path: 'user-plan',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/userplan/userplan.module').then(m => m.UserplanModule)
      },
        // }, {
      //   path: 'bootstrap-table',
      //   loadChildren: () => import('./pages/ui-elements/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module').then(m => m.BasicBootstrapModule),
      // }, {
      //   path: 'map',
      //   loadChildren: () => import('./pages/map/google-map/google-map.module').then(m => m.GoogleMapModule),
      // }, {
      //   path: 'user',
      //   loadChildren: () => import('./pages/user/profile/profile.module').then(m => m.ProfileModule)
      // }, {
      //   path: 'simple-page',
      //   loadChildren: () => import('./pages/simple-page/simple-page.module').then(m => m.SimplePageModule)
      // }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
