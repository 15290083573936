import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Partner Onboarding';
previousUrl:string;
  afterRedirect: string;
  urlId: number;
  constructor(private router: Router,private appservice:AppService) { }

  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
      // console.log(evt);
      if (!(evt instanceof NavigationEnd)) {

        return;
      }
      else{
        this.previousUrl=evt.url;
        this.appservice.previousUrl=evt.url;
        this.afterRedirect=evt.urlAfterRedirects;
        console.log(this.afterRedirect);
        this.urlId=evt.id;
        this.appservice.previousUrlId=evt.id;
        console.log(this.urlId);
        console.log(this.previousUrl);
      }
      window.scrollTo(0, 0);
    });
   }
}
