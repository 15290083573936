import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { UploadDocumentRoutingModule } from './upload-document-routing.module';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [UploadDocumentComponent],
  imports: [
    CommonModule,
    FormsModule,
    UploadDocumentRoutingModule,
    SharedModule
  ]
})
export class UploadDocumentModule { }
