
import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { HttpClient, HttpRequest,HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment,cloudrunEnpoint,localhostEnpoint,loginUrl,activeEndpoint } from "./../environments/environment"
import { ToastrService } from "ngx-toastr";
import { AuthService } from './auth.service';
// import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: 'root'
})
export class AppService {
  loginCode:any
  loginData:any
  reportId:any
  loginStatus:boolean = false
  spinnerStatus = new Subject()
  TokenData : any=""
  regObj : any="";
  globalHierarchy= {
    1 : "AMDR",
    2 : "AMR",
    3 : "ADR",
    4 : "AR"
  }
  globalHierarchyType= {
    1 : "Admin",
    2 : "Master",
    3 : "Distributor",
    4 : "Retailer"
  }
  tostType : any = {
    "success":"alert alert-success alert-with-icon",
    "info" : "alert alert-info alert-with-icon",
    "error" : "alert alert-danger alert-with-icon"
  }
serviceProviderList={};
benificialList={};
  userName: any;
ownertype="";
previousUrl:string;
previousUrlId:number;
selectedFeatureList:any;
editServiceProvider:boolean=false;
  constructor(
    private http : HttpClient,
    private toastr: ToastrService,
    private authService:AuthService
    // private spinner: NgxSpinnerService,
    ) { 

    }


    async generateSW(reqUrl: string, username: string = null) {
      
      
      // return new Promise<string>((res, rej) => {
    
          let bongui = new TextEncoder();
          let beetlejuice = bongui.encode("@#$jill90$=");
          var bullock = await crypto.subtle.importKey(
              "raw", beetlejuice,
              { name: "HMAC", hash: "SHA-256" },
              false, [ "sign" ])
            //  ).then(() => {
                 let deffl90$ty5 = 10000
                 let expiry = Date.now() + deffl90$ty5
                 let jill = btoa(Math.round(Math.random()).toString() + Date.now()+"Uio"+ Math.round(Math.random()).toString());
                 let url = new URL(reqUrl);
                 let jojo = btoa(username);
        
                 let jojobizzare = url.pathname + expiry;
                 var sec09gh7$88 :any = await crypto.subtle.sign(
                  "HMAC", bullock,
                  bongui.encode(jojobizzare) )
                  // .then(() => {
                  let dioadvebbt = btoa(String.fromCharCode(...new Uint8Array(sec09gh7$88)))
                  url.searchParams.set("jack", dioadvebbt)
                  url.searchParams.set("expiry", `${expiry}`)
                  url.searchParams.set('jill', jill)
                  url.searchParams.set('jojo', jojo)
        
                  // res(url.search);
                  return url.href;
                // });
          // });
      // });
  }

    spinnerUpdate(status:any){
      if(status == 'show'){
        this.spinnerStatus.next(true)
      }else if(status == 'hide'){
        this.spinnerStatus.next(false)
      }

    }

    notifyTost(message:any,type:any='',from:any='top',align:any="right",){
      this.toastr[type](
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+message+'</span>',
          "",
          {
            timeOut: 4000,
            enableHtml: true,
            // closeButton: true,
            toastClass: this.tostType[type],
            positionClass: "toast-" + from + "-" + align
          }
        );
    }
   

  validAuthenticate(loginCred:any):Observable<any>{
    console.log(loginCred);
    // return "";
    return this.http.post(environment.JavaEndPoint+"getlogintoken",loginCred)

  }
   authenticateLogin(swloginURL:any,loginCred:any):Observable<any>{
    // return ""
    console.log(loginCred);
    
    const headers = new HttpHeaders(
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization" : "Basic aXN1LWNsaWVudDppc3UtcGFzc3dvcmQ="
      },
      );
    return this.http.post(swloginURL,loginCred.toString(),{headers:headers})

  }
  registerPartner(partnerdata:any):Observable<any>{
    // return "";
    console.log(this.TokenData);
    console.log(partnerdata);
    const headers = new HttpHeaders({'authorization': this.TokenData});
    // headers.set('authorization', tokenObj.token);
    return this.http.post(environment.JavaEndPoint+"usecrisunweser",partnerdata,{headers:headers})
  }
  sendEmailOtp(){
    const headers = new HttpHeaders({'authorization': this.TokenData});
    return this.http.post(environment.JavaEndPoint+"generateOtpEmailOfNewUser",{email:this.regObj.email},{headers:headers})

  }
  checkUserAvailable(user_name:any){
    // const headers = new HttpHeaders({'authorization': this.TokenData});
    return this.http.post("https://us-central1-creditapp-29bf2.cloudfunctions.net/elasticuser_prod/check/user_name",{user_name})

  }

  registerBusinessInfo(businessObj:any){
   return this.http.post(activeEndpoint+"business_info/create_business",businessObj)
  }
getSelectServiceDetails(){
  return this.http.get(activeEndpoint+"admin_service/feature_list");
}  

adminSelectServiceDetails(searchObj:any){
  return this.http.post(activeEndpoint+"admin_service/admin_feature_list",searchObj);
}  
fetchUserServiceProvider(searchObj:any){
  return this.http.post(activeEndpoint+"admin_service/service_provider_list",searchObj);
}  


addSelectServiceDetails(obj:any){
  return this.http.post(activeEndpoint+"admin_service/assign_feature_new",obj);
}
getSelectedFeatureList(body:any){

  return this.http.post(activeEndpoint+"admin_service/admin_feature_list",body);
}
uploadDocument(fileObj){
  console.log(fileObj);
  fileObj.forEach((value, key) => {
    console.log(key + " " + value)
  });
  return this.http.post(activeEndpoint+"document/upload_document",fileObj);

}

createServiceProvider(providerObj:any,userName:any,serviceProviderType:any =""){
console.log(providerObj);
let body={
  "serviceProviderData":providerObj,
  userName,
  serviceProviderType
}
console.log(body);
// let provObj=JSON.stringify(body);
// console.log(provObj);
//return this.http.post(localhostEnpoint+"api/admin_service/create_service_provider",body)
return this.http.post(activeEndpoint+"admin_service/create_service_provider",body)
}

createBenificialOwner(benificialOwnerObj){
  console.log(benificialOwnerObj);
  this.userName=this.authService.getLoginDAta().userName;
  let body={
    "fullName":benificialOwnerObj.fullName,
    "dob":benificialOwnerObj.dob,
    "email":benificialOwnerObj.email,
    "mobile":benificialOwnerObj.mobile,
    "panNumber":benificialOwnerObj.panNumber,
    "adhaarNumber":benificialOwnerObj.adharNumber,
    "address":benificialOwnerObj.address,
    "state":benificialOwnerObj.state,
    "city":benificialOwnerObj.city,
    "userName":this.userName,
    "type":this.ownertype
    //"type":"beneficial/controlingPerson"
  }
  // console.log(body);
return this.http.post(activeEndpoint+"POB_benificial_owner/create",body)
}

getbenificialOwnerList(){
 // console.log(ownerTypeObj);
  let body={
    //"type":this.ownertype,
   // "username":this.userName
   "username":this.authService.getLoginDAta().userName
  }
  console.log(body);
return this.http.post(activeEndpoint+"POB_benificial_owner/getbeneficialowner",body)
}

createPlayStore(playStoreObj){
  this.userName=this.authService.getLoginDAta().userName;
  let body={
    "Id":playStoreObj.id,
    "Password":playStoreObj.password,
    "userName":this.userName,
  }
  console.log(body);
  return this.http.post(activeEndpoint+"playstore_credentials/create",body)
}

createDomainDetail(domainObj){
  this.userName=this.authService.getLoginDAta().userName;
  let body={
    "domainServiceProvider" : domainObj.domainServiceProvider,
    "domainName" : domainObj.domainName,
    "userName":this.userName
  }
  return this.http.post(activeEndpoint+"domain_details/create",body);
}

createAppDetails(appDetailsObj){

  return this.http.post(activeEndpoint+"app_details/create",appDetailsObj)
}

fetchUserPlan(userName:any){
  return this.http.post(activeEndpoint+"admin_service/plan_list",{userName})
}
updatePlanSpdData(resObj:any){
  return this.http.post(activeEndpoint+"admin_service/update_plan_sp",resObj)
}

updatePlanName(resObj:any){
  return this.http.post(activeEndpoint+"admin_service/update_plan_admin",resObj)
}

fetchTypeWisePlan(userName:any,planType:any){
  console.log(planType);
  
  return this.http.post(activeEndpoint+"admin_service/plan_list_type_wise",{userName,planType})
}

adminNewPlanCreate(newPlanObj:any){
  return this.http.post(activeEndpoint+"admin_service/create_admin_plan2",newPlanObj)
}
fetchApiPlanDetails(PlanDetailsObj:any){
  //it will fetch iserveu iserveu2 downline users plan details
  return this.http.post(activeEndpoint+"admin_service/fetch_admin_plan_details",PlanDetailsObj)
}
fetchAdminPlanDetails(PlanDetailsObj:any){
  return this.http.post(activeEndpoint+"admin_service/fetch_admin_plan_details2",PlanDetailsObj)
}
updatePlanDetails(planDetailsObj:any){
  return this.http.post(activeEndpoint+"admin_service/create_plan_properties",planDetailsObj)
}

fetchElasticQuery(searchKey:any){
  return this.http.post(activeEndpoint+"elastic/search_user",searchKey)
}

mapUserPlan(reqObj:any){
  return this.http.post(activeEndpoint+"admin_service/map_user_plan2",reqObj)
}

update_userSP(reqObj:any){
  return this.http.post(activeEndpoint+"admin_service/user_assign_sp",reqObj)
}

fetchAPIShareVAlue(reqObj:any){
  return this.http.post(activeEndpoint+"admin_service/fetch_api_plan_details",reqObj)
}

validateToken(token:any){
  const headers = new HttpHeaders(
    {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Authorization" : token//"Basic aXN1LWNsaWVudDppc3UtcGFzc3dvcmQ="
    },
    );
  return this.http.post(activeEndpoint+"auth/validate_token",{token},{headers:headers})
}


/**
 * User API Call
 */

fetchUserAssignPlan(userName:any){
  return this.http.post(activeEndpoint+"user_service/user_assign_plan",{userName})
}

fetchMD(payload:any){

  

  return this.http.post(payload.url,payload.reqbody)

}

}

