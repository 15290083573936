import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild
  } from '@angular/router';
  import { Observable } from 'rxjs/Observable'; 
  import { Injectable } from
   '@angular/core';
  import { AuthService } from './auth.service';
  import * as _ from 'lodash';
  
  @Injectable()
  export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(private authService: AuthService, private router: Router) {}
    authntic: any = "";
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
                 //   this.authntic = ;
                 // console.log('ghg',this.authService.getLoginDAta());
                  
          
            if (this.authService.isAuthenticated()) {
              return true;
            } else {
              this.router.navigate(['/authentication/login']);
            }
         
       
    }
  
    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.canActivate(route, state);
    }
  }
  
