import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth.service';

import { AppService } from './../../../app.service';
declare var swal: any;
@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {

  @ViewChild('labelImport', {static: false})
  labelImport:ElementRef
  @ViewChild('labelImportMOA', {static: false})
  labelImportMOA:ElementRef
  @ViewChild('labelImportAOA', {static: false})
  labelImportAOA:ElementRef
  @ViewChild('labelImportCompanyPANChange', {static: false})
  labelImportCompanyPANChange:ElementRef
  @ViewChild('labelImportDirectorDetailsChange', {static: false})
  labelImportDirectorDetailsChange:ElementRef
  @ViewChild('labelImportDirectorAdharChange', {static: false})
  labelImportDirectorAdharChange:ElementRef
  @ViewChild('labelImportDirectorPANChange', {static: false})
  labelImportDirectorPANChange:ElementRef
  @ViewChild('labelImportGSTCertificateChange', {static: false})
  labelImportGSTCertificateChange:ElementRef
  @ViewChild('labelImportCancelledChequeChange', {static: false})
  labelImportCancelledChequeChange:ElementRef
  @ViewChild('labelImportLogoChange', {static: false})
  labelImportLogoChange:ElementRef
  pageTitle : String = "Business Documents";
  uploadDocForm : FormGroup;
  comanyRegFileName:String = ""
  AOAFileName:String = ""
  MOAFileName:String = ""
  PANFileName:String = ""
  directorDetailsFileName = ""
  directorAdharFileName = ""
  directorPANFileName = ""
  GSTFileName = ""
  cancelledChequeFileName = ""
  logoFileName:String = ""
  fileToUpload: File = null;
  formSubmit: boolean=false;
  compRegfile: File;
  selectedFiles: FileList;
  uploadingMsg: string;
  fileName: File;
  AOAfile:File;
  uploadingAOAMsg: string;
  MOAfile: File;
  uploadingMOAMsg: string;
  PANfile: File;
  uploadingPANMsg: string;
  directorDetailsfile: File;
  uploadingdirectorDetailsMsg: string;
  directorAdharfile: File;
  uploadingdirectorAdharMsg: string;
  directorpanfile: File;
  uploadingdirectorPANMsg: string;
  gstCertificatefile: File;
  uploadinggstCertificateMsg: string;
  cancelledChequefile: File;
  uploadingcancelledChequeMsg: string;
  logofile: File;
  uploadingLogoMsg: string;
  uploadToggle: boolean;
  uploadcancelledChequeToggle: boolean;
  uploadGSTtoggle: boolean;
  uploaddirectorPANtoggle: boolean;
  uploaddirectorAdhartoggle: boolean;
  uploaddirectorDetailstoggle: boolean;
  uploadCompanyPANtoggle: boolean;
  uploadCompanyAOAtoggle: boolean;
  uploadCompanyMOAtoggle: boolean;
  uploadLogoToggle: boolean;
  validFileExtensions = [".doc",".pdf"]; 
  fileValidation: string;
  fileToggle: boolean=false;
  AOAfileToggle: boolean;
  MOAfileToggle: boolean;
  CompanyFileToggle: boolean;
  DirectorDetailsToggle: boolean;
  DirectorAdharToggle: boolean;
  DirectorPANToggle: boolean;
  gstfileToggle: boolean;
  cancelledChequefileToggle: boolean;
  logofileToggle: boolean;
  userName: any;
  
  constructor(private fb:FormBuilder,private appService:AppService,private router:Router,private authService:AuthService) {

   }
 
  ngOnInit() {
this.userName=this.authService.getLoginDAta().userName;
    this.uploadDocForm=this.fb.group({
      companyReg:new FormControl("",[Validators.required]),
      companyMOA:new FormControl("",[Validators.required]),
      companyAOA:new FormControl("",[Validators.required]),
      companyPanCard:new FormControl("",[Validators.required]),
      directorDetails:new FormControl("",[Validators.required]),
      directorAdharCard:new FormControl("",[Validators.required]),
      directorPanCard:new FormControl("",[Validators.required]),
      gstCertificate:new FormControl("",[Validators.required]),
      cancelledCheque:new FormControl("",[Validators.required]),
      logo:new FormControl("",[Validators.required])
    })
  }
 
  uploadCompanyFileChange(event:any){
    this.comanyRegFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])

  }

  uploadMOAFileChange(event:any){
    this.MOAFileName = event.target.files[0]['name']
    console.log(this.MOAFileName);
    console.log(event.target.files[0]['name'])
  }

  uploadAOAFileChange(event:any){
    this.AOAFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }

  uploadPANFileChange(event:any){
    this.PANFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }

  uploadDirectorFileChange(event:any){
    this.directorDetailsFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }
  uploaddirectorAdharFileChange(event:any){
    this.directorAdharFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }
  uploaddirectorPANFileChange(event:any){
    this.directorPANFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }
  uploadGSTFileChange(event:any){
    this.GSTFileName = event.target.files[0]['name']
    console.log(event.target.files[0]['name'])
  }

  uploadcancelledChequeFileChange(event:any){
    this.cancelledChequeFileName = event.target.files[0]['name']
    console.log(this.cancelledChequeFileName);
    console.log(event.target.files[0]['name'])
  }

  uploadFileChange(event:any){
    this.logoFileName = event.target.files[0]['name']
    console.log(this.logoFileName);
    console.log(event.target.files[0]['name'])

  }

  onFileChange(evt) {

  const filetype=evt.target.files[0]['name'];
  const filetype1=filetype.lastIndexOf(".") + 1;
  const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
  if(filetype2=="doc" || filetype2=="pdf"){

  // else{
  //   this.fileToggle=true;
  //   this.fileValidation="Only accept .doc or .pdf type";
  // }
//-------------------------------------------------------------------------------------------------------------//
    this.labelImport.nativeElement.innerText=evt.target.files[0]['name'];
    this.fileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.compRegfile=this.selectedFiles.item(0);
  // alert("Are you sure want to upload File??");
  let uploadFile=confirm("Are you sure want to upload File??")
  // alert(uploadFile);
   // this.router.navigate(["/authentication/registration"]);
    if(uploadFile==true){
      
//   swal({text:"Are you sure want to upload File??",
//   type:"warning",
//   showCancelButton: true,
//  }
//   );
    console.log(this.compRegfile);
   console.log(this.uploadDocForm.controls.companyReg.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.compRegfile);
   formData.append('documentName',"companyRegCertificate");
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadToggle=false;
  this.fileToggle=false;
  this.uploadingMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadToggle=true;
      this.uploadingMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
// else{
//   this.labelImport.nativeElement.innerText="";
// }
  }
else{
  this.fileToggle=true;
  this.fileValidation="Only accept .doc or .pdf File";

}
  
  }

  onAOAFileChange(evt) {
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
    //-----------------------------------------------------------------------------//
    this.labelImportAOA.nativeElement.innerText=evt.target.files[0]['name'];
    this.AOAFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.AOAfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.AOAfile);
   console.log(this.uploadDocForm.controls.companyAOA.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.AOAfile);
   formData.append('documentName',"companyAOA")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadCompanyAOAtoggle=false;
  this.AOAfileToggle=false;
  this.uploadingAOAMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadCompanyAOAtoggle=true;
      this.uploadingAOAMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
    }

    else{
      this.AOAfileToggle=true;
      this.fileValidation="Only accept .doc or .pdf File";
    }
   
  }

  onMOAFileChange(evt) {

    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportMOA.nativeElement.innerText=evt.target.files[0]['name'];
    this.MOAFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.MOAfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.MOAfile);
   console.log(this.uploadDocForm.controls.companyMOA.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.MOAfile);
   formData.append('documentName',"companyMOA")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadCompanyMOAtoggle=false;
  this.MOAfileToggle=false;
  this.uploadingMOAMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadCompanyMOAtoggle=true;
      this.uploadingMOAMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
    else{
    
        this.MOAfileToggle=true;
        this.fileValidation="Only accept .doc or .pdf File";
    }
  }

  onCompanyPANChange(evt){
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportCompanyPANChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.PANFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.PANfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.PANfile);
   console.log(this.uploadDocForm.controls.companyAOA.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.PANfile);
   formData.append('documentName',"companyPAN")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadCompanyPANtoggle=false;
  this.CompanyFileToggle=false;
  this.uploadingPANMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadCompanyPANtoggle=true;
      this.uploadingPANMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
    else{
    
      this.CompanyFileToggle=true;
      this.fileValidation="Only accept .doc or .pdf File";
  }

  }
  onDirectorDetailsChange(evt){
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportDirectorDetailsChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.directorDetailsFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.directorDetailsfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.directorDetailsfile);
   console.log(this.uploadDocForm.controls.directorDetails.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.directorDetailsfile);
   formData.append('documentName', "directorDetails")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploaddirectorDetailstoggle=false;
  this.DirectorDetailsToggle=false;
  this.uploadingdirectorDetailsMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe(res=>{
      this.appService.spinnerUpdate("hide");
      this.uploaddirectorDetailstoggle=true;
      this.uploadingdirectorDetailsMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
  else{
    this.DirectorDetailsToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }
  onDirectorAdharChange(evt){
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//

    this.labelImportDirectorAdharChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.directorAdharFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.directorAdharfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.directorAdharfile);
   console.log(this.uploadDocForm.controls.directorAdharCard.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.directorAdharfile);
   formData.append('documentName', "directorAadhar")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploaddirectorAdhartoggle=false;
  this.DirectorAdharToggle=false;
  this.uploadingdirectorAdharMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploaddirectorAdhartoggle=true;
      this.uploadingdirectorAdharMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
  else{
    this.DirectorAdharToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }
  
  onDirectorPanCardChange(evt){

    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportDirectorPANChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.directorPANFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.directorpanfile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.directorpanfile);
   console.log(this.uploadDocForm.controls.directorPanCard.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.directorpanfile);
   formData.append('documentName', "directorPAN")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploaddirectorPANtoggle=false;
  this.DirectorPANToggle=false;
  this.uploadingdirectorPANMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploaddirectorPANtoggle=true;
      this.uploadingdirectorPANMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
  else{
    this.DirectorPANToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }

  onGstCertificateChange(evt){
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportGSTCertificateChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.GSTFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.gstCertificatefile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.gstCertificatefile);
   console.log(this.uploadDocForm.controls.gstCertificate.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.gstCertificatefile);
   formData.append('documentName',"GSTCertificate")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadGSTtoggle=false;
  this.gstfileToggle=false;
  this.uploadinggstCertificateMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadGSTtoggle=true;
      this.uploadinggstCertificateMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
(error:any)=>{
  this.appService.spinnerUpdate("hide")
  console.log(error.error);
  this.appService.notifyTost(error.error.message,'error')
}
    )
  }
  else{
    this.gstfileToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }
  onCancelledChequeChange(evt){

    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportCancelledChequeChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.cancelledChequeFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.cancelledChequefile=this.selectedFiles.item(0);
   confirm("Are you sure want upload File??");
    console.log(this.cancelledChequefile);
   console.log(this.uploadDocForm.controls.gstCertificate.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.cancelledChequefile);
   formData.append('documentName', "cancelAmountProof")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadcancelledChequeToggle=false;
  this.cancelledChequefileToggle=false;
  this.uploadingcancelledChequeMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadcancelledChequeToggle=true;
      this.uploadingcancelledChequeMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
  else{
    this.cancelledChequefileToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }

  onLogoChange(evt){
    const filetype=evt.target.files[0]['name'];
    const filetype1=filetype.lastIndexOf(".") + 1;
    const filetype2=filetype.substr(filetype1,filetype.length).toLowerCase();
    if(filetype2=="doc" || filetype2=="pdf"){
//-------------------------------------------------------------------------------------//
    this.labelImportLogoChange.nativeElement.innerText=evt.target.files[0]['name'];
    this.logoFileName=evt.target.files[0]['name'];
    this.selectedFiles=evt.target.files;
    console.log(this.selectedFiles.item(0).name);
   this.logofile=this.selectedFiles.item(0);
   confirm("Are you sure want to upload File??");
    console.log(this.logofile);
   console.log(this.uploadDocForm.controls.gstCertificate.value);
    const formData=new FormData();
   formData.append('userName',this.userName);
   formData.append('fileupload',this.logofile);
   formData.append('documentName',"logo")
   console.log(JSON.stringify(formData));
   formData.forEach((value, key) => {
    console.log(key + " " + value)
  });
  
  this.appService.spinnerUpdate("show");
  this.uploadLogoToggle=false;
  this.logofileToggle=false;
  this.uploadingLogoMsg="File Uploading..."
    this.appService.uploadDocument(formData).subscribe((res:any)=>{
      this.appService.spinnerUpdate("hide");
      this.uploadLogoToggle=true;
      this.uploadingLogoMsg="File Uploaded Successfully";
      console.log(res);
      this.appService.notifyTost("Uploaded Successfully",'success')
    },
    (error:any)=>{
      this.appService.spinnerUpdate("hide")
      console.log(error.error);
      this.appService.notifyTost(error.error.message,'error')
    }
    )
  }
  else{
    this.logofileToggle=true;
    this.fileValidation="Only accept .doc or .pdf File";
  }
  }
onSubmit(){
  this.formSubmit=true;
  console.log(this.uploadDocForm.value);
  this.router.navigateByUrl("/select-service");
}
back(){
  this.router.navigateByUrl("/business-info");
}
}
