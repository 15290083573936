import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
history:string[]=[];
  previousUrl: string;
  constructor(private router:Router,private location:Location) {
    this.router.events.subscribe((event)=>{
      if(event instanceof NavigationEnd){
       // this.history.push(event.urlAfterRedirects);
       this.previousUrl=event.url;
       console.log(this.previousUrl);
      }
    })
   }

   back(){
     this.history.pop()
     if(this.history.length>0){
       this.location.back();
     }
     else{
       this.router.navigateByUrl("/")
     }
   }
}
