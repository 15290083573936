import { from } from 'rxjs';
import {localhostEnpoint,cloudrunEnpoint,activeEndpoint,loginUrl} from "./../../environments/environment"

export const paths = {
    auth: "auth",
    cache: "cache",
    fake: "fake",
    error: "error",
    profiler: "profiler",
    notify: "notify",
    header: "header",
    convert: "convert",
    loader: "loader",
    https: "https",
    unauthUrl : [
        // activeEndpoint+"admin_service/admin_feature_list",
        loginUrl,activeEndpoint+'auth/validate_token'
    ]
  };
  